.titlePro {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  &::after {
    content: "";
    margin: 5px 0;
  }
  &::before {
    content: "";
    margin: 5px 0;
  }
}
.title {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  white-space: nowrap;
  margin: 10px 0;

  &::after {
    content: "";
    margin: 5px 0;
    padding: 3px;
    width: 50px;
    background: var(--secondary-color3);
  }
}

.app__container {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  // min-height: 100%;
  max-width: 1500px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 auto;
}
.navigator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.rightSide__container {
  min-height: 100%;
  width: 200px;
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  // flex: 1;
  width: 100%;
  flex-flow: column nowrap;
  // flex-flow: row nowrap;
  // padding: 4rem 2rem;

  @media screen and (max-width: 450px) {
    padding: 4rem 1rem 2rem;
  }
}

// .copyright {
//   width: 100%;
//   padding: 2rem 0 0;

//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   align-items: flex-end;

//   p {
//     text-transform: uppercase;
//     color: var(--black-color);
//   }
// }

.head-text {
  font-size: 2.5rem;
  font-weight: 100;
  text-align: center;
  color: var(--black-color);
  // text-transform: capitalize;
  white-space: nowrap;
  font-family: "Monoton", cursive;
  display: inline-block;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
  position: relative;

  &:hover::after {
    width: 100%;
  }
  &::after {
    content: "";
    width: 0%;
    height: 3px;
    right: 0;
    bottom: 0;
    background: var(--secondary-color3);
    position: absolute;
    transition: width 0.3s ease;
  }
  span {
    margin-left: 0px !important;
    margin-right: 2px !important;
    color: var(--white-color);
    display: inline-block;
    padding: 5px 3px;
    background: var(--secondary-color3);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 450px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
  // padding: 8px 10px;

  @media screen and (max-width: 900px) {
    font-size: 1.3rem;
  }
}

.bold-text {
  font-size: 1.2rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 500px) {
  .app__navigation,
  .app__social,
  .navigator {
    display: none;
  }

  .copyright {
    padding: 2rem;
  }
}

.app__home {
  height: 100vh;
  width: 100vw;

  .navigator {
    display: none;
  }
}
.skill-section {
  background-color: var(--white-color);
}
.portfolio-section {
  background-color: rgba(0, 124, 237, 0.01);
}
.services-section {
  background-color: var(--white-color);
}
.contact-section {
  background-color: var(--white-color);
  .navigator {
    display: none;
  }
}
#root {
  overflow: hidden;
}
.app__center-trick {
  width: 90%;
  margin: 0 auto;
}
