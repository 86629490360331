// .app__footer {
//   // flex: 1;
//   width: 100%;
//   flex-direction: column;
//   padding: 0;
//   margin: 0;
//   border: 1px solid red;
// }
// .footer {
//   width: 100% !important;
//   padding: 0;
//   margin: 0;
// }
// .app__footer-cards {
//   width: 60%;
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;
//   margin: 4rem 2rem 2rem;
//   justify-content: space-evenly;

//   .app__footer-card {
//     min-width: 290px;
//     display: flex;
//     flex-direction: row;
//     justify-content: flex-start;
//     align-items: center;

//     margin: 1rem 0;
//     padding: 1rem;
//     border-radius: 10px;
//     cursor: pointer;
//     background-color: #fef3f5;
//     transition: all 0.3s ease-in-out;

//     img {
//       width: 40px;
//       height: 40px;
//       margin: 0 0.7rem;
//     }
//     p {
//       font-weight: 600;
//     }
//     a {
//       text-decoration: none;
//       font-weight: 500;
//     }
//     &:hover {
//       box-shadow: 0 0 25px #fef4f5;
//     }
//     @media screen and(max-width:450px) {
//       width: 100%;
//     }
//   }
//   @media screen and(max-width:768px) {
//     width: 100%;
//   }
// }

// .app__footer-cards .app__footer-card:last-child {
//   background-color: #f2f7fb;
// }
// .app__footer-form {
//   width: 60%;
//   flex-direction: column;
//   margin: 1rem 2rem;

//   div {
//     width: 100%;
//     margin: 0.75rem 0;
//     border-radius: 10px;
//     cursor: pointer;
//     background-color: var(--primary-color);
//     transition: all 0.3s ease-in-out;

//     input,
//     textarea {
//       width: 100%;
//       padding: 0.95rem;
//       border: none;
//       outline: none;
//       border-radius: 7px;
//       background-color: var(--primary-color);
//       font-family: var(--font-base);
//       color: var(--secondary-color);
//     }
//     textarea {
//       height: 170px;
//     }
//     &:hover {
//       box-shadow: 0 0 25px var(--primary-color);
//     }
//   }
//   button {
//     padding: 1rem 2rem;
//     border-radius: 10px;
//     border: none;
//     outline: none;
//     font-weight: 500;
//     color: var(--white-color);
//     margin: 2rem 0 0 0;
//     font-family: var(--font-base);
//     cursor: pointer;
//     background-color: var(--secondary-color);
//   }
//   @media screen and(max-width:768px) {
//     width: 100%;
//     margin: 1rem 0;
//   }
// }

/* Footer Signature section */
.footer-signature {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  // background: var(--primary-color2);
  --c1: #007ced;
  --c2: #008bf5;
  background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    var(--x, 0) / 200%;
  color: white;
  // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  color: var(--color-white);
  padding: 20px;

  a {
    text-decoration: none;
    color: var(--color-white);
  }
  &:hover {
    --x: 100%;
  }
}

.footerbox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap-reverse;
}

.footerbox__signature-disclaimer,
.footerbox__signature-contact {
  margin: 10px 0;
}

.footerbox__signature-disclaimer span {
  margin: 0 10px;
  cursor: pointer;
  color: var(--color-white);
}

.footer-btn-a {
  display: inline-block;
}

.footer-btn {
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding: 10px 30px;
  border: 2px solid var(--color-white);
  color: var(--color-white);
  background: transparent;
  border-radius: 10px;
  transition: all 0.3s ease;
}

.footer-btn:hover {
  // background: var(--gradient-bg);
  color: var(--color-white);
  // border: none;
  transform: translateX(-3px);
}

.footer-btn:hover .icon-dev {
  transform: translateX(20px);
  // margin: 0 0 0 25px;
  color: var(--color-white);
}

.icon-dev {
  font-size: 25px;
  // margin: 0 0 0 10px;
  transition: all 0.3s;
  color: var(--color-white);
}

/* footer icons */
.footer__iconBox {
  margin: 8px;
  display: flex;
}

.footer-icon_holder {
  margin: 0 5px;
  width: 34px;
  height: 34px;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  // background: var(--primary-color2);
  border: 1px solid var(--color-white);
}

.footer__iconBox-icon {
  // margin: 0 10px;
  color: var(--color-white);
}

.footer-icon_holder a {
  text-decoration: none;
  font-size: 13px;
}

.footer-icon_holder:hover {
  border: 2px solid var(--color-white);
  // border-color: var(--primary-color2);
  background: transparent;
}

.footer-icon_holder:hover .footer__iconBox-icon {
  color: var(--color-white);
}

/************************  */
