.smooth__controller {
  z-index: 6;
  top: 20px;
  right: 50px;
  position: fixed;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}
@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 var(--primary-color2), 0 0 0 0 var(--primary-color2);
  }

  80% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0), 0 0 0 20px rgba(0, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 20 var(--primary-color2), 0 0 0 40 var(--primary-color2);
  }
}

.hamburger-menu {
  z-index: 5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 3rem;
  height: 3rem;
  z-index: 100;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-color2);
  transition: all 0.5s ease-in-out;
  animation: ripple 2s infinite;

  // 0% {
  //   box-shadow: 0 0 0 0 var(--primary-color2), 0 0 0 0 var(--primary-color2);
  // }

  // 80% {
  //   box-shadow: 0 0 0 20px rgba(0, 0, 0, 0), 0 0 0 40px rgba(0, 0, 0, 0);
  // }

  // 100% {
  //   box-shadow: 0 0 0 20 var(--primary-color2), 0 0 0 40 var(--primary-color2);
  // }

  &:hover {
    transform: translateY(10px);
  }
  .bar {
    position: relative;
    width: 2rem;
    height: 3px;
    border-radius: 3px;
    background-color: #fff;
    transition: 0.5s;
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 2.1rem;
      height: 3px;
      border-radius: 3px;
      background-color: #fff;
      transition: 0.5s;
    }

    &:before {
      transform: translateY(-9px);
    }
    &:after {
      transform: translateY(9px);
    }
  }
}
.smoothmenushow .hamburger-menu {
  background: #fff;
}
.smoothmenushow .bar {
  background-color: transparent;
  transform: rotate(360deg);
}

.smoothmenushow .bar:before {
  transform: translateY(0) rotate(45deg);
  background-color: var(--primary-color2);
}

.smoothmenushow .bar:after {
  transform: translateY(0) rotate(-45deg);
  background-color: var(--primary-color2);
}

.smoothmenu {
  position: fixed;
  padding: 7rem 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: var(--primary-color2);
  transition: all 0.3s ease;
  transform: translateX(220%);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16);
  z-index: 5;
}

.smoothmenushow {
  transform: translateX(0);
}

// Side bar links

.sidebar-links {
  width: 100%;
  height: 100%;
}
.sidebar-links-nav {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none;
    padding: 15px 30px;
    display: block;
    color: #fff;
    width: 100%;
    height: 100%;
    &:hover {
      color: #cfcde7;
    }
    &:hover .tooltip {
      display: flex;
    }
    .tooltip {
      border-radius: 10px;
      pointer-events: none;
      transition: all 0.3s;
      text-align: center;
      white-space: nowrap;
      left: -100px;
      top: 35%;
      display: none;
      position: absolute;
      align-self: flex-end;
      transform: translateX(50%);
      padding: 5px 8px;
      font-size: 15px;
      background-color: var(--primary-color2);
      color: #fff;
      box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

      &:nth-of-type(3) {
        left: -10px !important;
      }
    }
  }
}

// Manual reset for Tooltip

.sidebar-links-ul {
  li:nth-child(2) .tooltip {
    left: -125px !important;
  }
  li:nth-child(3) .tooltip {
    left: -115px !important;
  }
  li:nth-child(4) .tooltip {
    left: -80px !important;
  }
  li:nth-child(5) .tooltip {
    left: -125px !important;
  }
}
.side_nav-icon {
  font-size: 25px;
}
