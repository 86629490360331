.contact-response {
  padding: 20px 20px 60px 20px;
  width: 70%;
}
.contact {
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  // padding: 20px 40px;

  @media (max-width: 850px) {
    padding: 0;
  }
  p {
    padding: 0 20px;
    margin: 15px 0;
  }
}
.contact-container {
  box-shadow: 7px 11px -10px rgba(0, 0, 0, 0.33);
  width: 70%;
  // max-height:400px ;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  // justify-content: space-between;
  border: 1px solid var(--primary-color2);
  margin: 0 auto;
  overflow: hidden;
  border-radius: 25px;
  background: var(--color-gray);
  margin: 20px auto;
  // & > div {flex: 0.5;}

  @media (max-width: 850px) {
    & {
      width: 90%;
      border: none;
      justify-content: center;
      flex-flow: column-reverse nowrap;
      // width: 100%;
      margin: 15px auto;
    }
    & > div {
      min-width: 100%;
      flex: 1;
    }
    & .app__contact-card {
      height: 150px;
      // border: 2px solid black;
    }
  }
}
.isound {
  bottom: 0;
  left: 30px;
}
.iboxball {
  bottom: 0;
  right: 0;
}
.iboxball {
  top: 0;
  right: 10px;
}
.app__contact-card {
  position: relative;
  width: 30%;
  height: 100%;
  min-height: 150px;
  // padding: 50px ;
  // display: flex;
  h3 {
    margin: 20px;
  }
}
.contact__card-info {
  z-index: 2;
  // border: 1px solid red;
  // background: #fff;
  padding: 10px 20px 20px 20px;
  // margin: 20px 0;
  display: flex;
}
.fastlane-text {
  display: none;
}
.contact__card-link {
  cursor: pointer;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  transition: all 0.3s ease;
  text-decoration: none;
  margin: 12px 0;

  &,
  &:focus,
  &:visited {
    color: var(--color-gray);
  }

  &:hover {
    transform: translateY(-3px);
  }
  .contact__card-iconHolder {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    --c1: #007ced;
    --c2: #008bf5;
    cursor: pointer;
    background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
      var(--x, 0) / 200%;
    color: var(--color-white);
    margin: 0 10px 0 0;
    &:hover {
      --x: 100%;
    }
    svg {
      font-size: 20px;
    }
  }
}

.app__contact-form {
  width: 70%;
  // max-width: 80%;
  // border: 1px solid black;
  padding: 20px 2rem;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  .form-input {
    box-sizing: border-box;
    background: #f3f3f3;
    border: none;
    display: flex;
    font-size: 0.9rem;
    padding: 0.98rem 1rem;
    margin: 0.4rem 0;
    border-radius: 10px;
    transition: all 0.3s ease;
    outline: none;
    min-width: 100%;
  }

  textarea {
    resize: none;
    min-height: 140px;
    min-width: 100%;
    resize: vertical;
  }
}
.form-btn {
  --c1: #007ced;
  --c2: #008bf5;
  border: none;
  font-weight: 500;
  outline: none;
  padding: 0.85rem 2rem;
  // border-radius: 2rem;
  border-radius: 10px;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease;
  cursor: pointer;
  background: linear-gradient(80deg, var(--c1), var(--c2) 51%, var(--c1))
    var(--x, 0) / 200%;
  color: white;
  z-index: 3;
  margin: 15px 0;

  &:hover {
    --x: 100%;
    transform: translateY(10px);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
}

.contact-resend {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  svg {
    color: var(--secondary-color3);
    font-size: 80px;
    margin: 15px 0;
  }
  button {
    background: var(--secondary-color3);
  }
}

@media (max-width: 851px) {
  .app__contact-form {
    padding: 20px;
    width: 100%;
  }
  .contact__card-info {
    flex-direction: column;
    padding: 10px 20px 40px 20px;
  }
  .fastlane-text {
    display: inline-block;
  }
}

.toast {
  position: fixed;
  // left: 1rem; /* Adjusted for responsiveness */
  background-color: var(--green-color);
  z-index: 40;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  // display: flex;
  // justify-content: space-between;
  align-items: center;
  max-width: 60%; /* Make toast responsive */
  // min-width: 150px; /* Ensure minimum width for readability */
  word-wrap: break-word; /* Ensure text wraps within the container */
  // word-wrap: nowrap; /* Ensure text wraps within the container */
}
.statusToast{
  top: 8.3rem; /* Adjusted for responsiveness */
  right: 6rem; /* Adjusted for responsiveness */

}
.messageToast{
    top: 5.5rem; /* Adjusted for responsiveness */
  right: 6rem; /* Adjusted for responsiveness */

}
.toast-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--secondary-color3);

  animation: shrink 5s linear forwards;
}
.success-toast{

  background-color: var(--green-color)!important;
}
.success-toast .toast-progress{
  background-color: var(--secondary-color3)!important;

}
.error-toast {
  background-color: var(--red-color);
}
.error-toast .toast-progress{
  background-color: var(--secondary-color3)!important;

}

@keyframes shrink {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

/* Responsive styles */
@media (max-width: 600px) {
  .toast {
    // right: 0.5rem;
    left: 0.5rem;
    // bottom: 0.5rem;
    font-size: 0.875rem; /* Smaller text on smaller screens */
    // padding: 0.75rem; /* Adjust padding for smaller screens */
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: flex-start; /* Align items to the start for better readability */
  }
.statusToast{
    top: 5.5rem;

}
.messageToast{
    top: 2.5rem; /* Adjusted for responsiveness */
}
  .toast-progress {
    height: 3px; /* Adjust progress bar height for smaller screens */
  }
}