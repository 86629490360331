.no-portfolio {
  display: flex;
  height: 400px;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 60px 20px 0 20px;

  button {
    background: var(--secondary-color3);
    padding: 8px 10px;
    color: var(--white-color);
    border-radius: 5px;
    border: none;
    outline: none;
    margin-left: 20px;
  }
  @media (max-width: 768px) {
    flex-flow: column;
    align-items: center;
    justify-content: flex-start;

    button {
      margin: 15px 0 0 0;
    }
  }
}
.app__works {
  padding: 30px 0;
  flex: 1;
  width: 100%;
  flex-direction: column;
  @media (max-width: 501px) {
    padding: 30px 20px;
  }
}
.app__work-filter {
  display: flex;
  flex-direction: row;
  // justify-content: flex-start;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 0;

  .app__work-filter-item {
    // padding: 0.5rem 1rem;
    // border-radius: 0.5rem;
    border-radius: 10px;
    padding: 4px 8px;
    background-color: var(--white-color);
    color: #000;
    // font-weight: 800;
    font-weight: 450;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;

    &:hover {
      background-color: var(--secondary-color3);
      color: var(--white-color);
    }
    @media screen and (max-width: 900px) {
      // border-radius: 13px;
      font-size: 15px;
    }
  }
  .item-active {
    background-color: var(--secondary-color3);
    color: var(--white-color);
  }
}
.app__work-wrapper {
  display: flex;
  flex-flow: row nowrap;
  position: relative;
  cursor: pointer;
  margin: 10px 20px;
  transition: all 0.5s ease;
  overflow: hidden;

  &:hover {
    transform: translateY(-8px);
  }
  &:hover .app__work-title a {
    color: var(--primary-color2);
  }
  &:hover .app__work-actions {
    background: var(--secondary-color3);
    a {
      color: var(--white-color);
    }
  }
  &:hover .app__work-descontainer {
    visibility: visible;
    height: 100%;
  }
}
.app__work-title {
  // background: var(--secondary-color3);
  cursor: pointer;
  z-index: 5;
  transform-origin: top left;
  left: -3px;
  top: 0px;
  transform: rotate(90deg);
  position: absolute;

  display: flex;
  // height: 100%;
  width: 100%;
  // border: 1px solid red;
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-color2);
  // border: 1px solid red;
  white-space: nowrap;

  a {
    color: var(--secondary-color3);
    text-decoration: none;
    text-transform: uppercase;
  }
}
.app__work-portfolio {
  display: flex;
  flex-wrap: wrap;
  text-align:center;
  justify-content: center;
  // justify-content: flex-start;
  align-items: center;

  .app__work-item {
    // display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    position: relative;
    // border: 1px solid red;
    transition: all 0.3s ease;
    width: 320px;
    // min-height: 250px;
    // width: 250px;
    // min-height: 320px;
    transition: all 0.3s ease;
    display: flex;
    // border: 1px solid black;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: ;
    // margin: 10px;
    border-radius: 0.5rem;
    color: #000;
    background-color: var(--white-color);
    // overflow: hidden;
    // padding: 1rem;

    @media screen and (min-width: 2000px) {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }
    @media screen and (max-width: 300px) {
      width: 100%;
      margin: 1rem;
    }
  }
}

.app__work-img {
  width: 100%;
  height: 150px;
  position: relative;

  img {
    object-fit: cover;
    object-position: top left;
    height: 100%;
    width: 100%;
  }
  @media screen and (min-width: 2000px) {
    height: 350px;
  }
}
.app__work-descontainer {
  text-decoration:none;
  padding: 15px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 0%;
  visibility: hidden;
  background: var(--blue-light-gradient);
  color: var(--white-color);
  transition: height 0.5s ease;
}
.app__work-hover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  opacity: 0;
  transform: all 0.3s ease;

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    color: var(--white-color);
    margin: 1rem;
    background-color: rgba(0, 0, 0, 0.5);
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;

    svg {
      width: 50%;
      height: 50%;
      color: var(--white-color);
    }
  }
}
.app__work-tech {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0;
  
  span {
    // border: 1px solid #999;
    color: var(--white-color);
    margin: 0 3px;
    padding: 3px;
    border-radius: 5px;
    font-size: 12px;
    background: var(--secondary-color3);
    text-wrap: nowrap;
  }
}

.app__work-content {
  display: flex;
  // padding: 0.5rem;
  width: 100%;
  position: relative;
  flex-direction: column;

  h4 {
    // margin-top: 1rem;
    line-height: 1.5;
  }
}
.app__work-tag {
  // border: 1px solid red;
  // position: absolute;
  left: 0;
  bottom: 0;
  padding: 9px;
  text-wrap:nowrap;
  text-transform: uppercase;
  font-size: 12px;
  background: var(--secondary-color3);
  border-top-right-radius: 8px;
  color: var(--white-color);
}

.app__work-desc {
  // border: 1px solid red;
  p {
    color: var(--white-color);
  }
}

.app__work-actions {
  display: flex;
  width: 100%;
  align-items: center;
  height:34px;

  a {
    font-size: 20px;
    display:flex;
    flex:1;
    justify-content:center;
    height:100%;
    text-decoration: none;
    color: var(--primary-color2);
  }
}
